import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../config/Config';

const TablePlaylists = ({xToken,slug,isActivePanel,handleBtnModal,dataFetch}) => {

  const [playlists, setPlaylist] = useState([]);
  let numberPlaylist = 1;

  useEffect(()=>{
    fetchPlaylists();
    dataFetch(fetchPlaylists)
  },[])

  

  const fetchPlaylists= async()=>{ 
    try {
      const data = await fetchData(`course/${slug}/playlists`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setPlaylist(data.data)
    } catch (error) {
      alert(error);
    }
  }

  const handleDelete = async(id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const data = await fetchData(`course/playlist/${id}`,{
          method : 'DELETE',
          credentials: 'include',
          headers : {
            'Authorization': `Bearer ${xToken}`,
          }
        });
        fetchPlaylists();
      } catch (error) {
        alert(error);
        //console.error('Error: ', error);
      }
    } else {
      console.log(`Item with id ${id} not deleted`);
    }
  };
  
  return (
    <div className={`${isActivePanel.playlists ? '' : 'hidden'} w-full shadow-md p-2 lg:p-4`} data-panel="playlists">
      <div className="flex justify-between items-center gap-2 py-4 px-2">
        <div className="w-1/2">
          <button data-target="playlists" onClick={(e) =>
                handleBtnModal(e, { target: 'playlist'})
              } type="button" title="Add playlist" className="bg-green-500 text-white rounded-md py-2 px-4 hover:bg-green-600 ease-in-out duration-300"><i className="fa-solid fa-plus mr-2" />Add playlist</button>
        </div>
      </div>
      {/* Table */}
      <div>
        <div className="overflow-y-auto">
          <table className="w-full">
            <thead className="bg-slate-600 text-white h-16 px-4">
              <tr className="text-left">
                <th className="px-6 py-4 ">Action</th>
                <th className="px-2 py-4 ">No</th>
                <th className="px-6 py-4 ">Playlist</th>
                <th className="px-6 py-4 ">Status</th>
                <th className="px-6 py-4 whitespace-nowrap">Is Required?</th>
              </tr>
            </thead>
            <tbody>
              { playlists.map(playlist=>(
                <tr key={playlist.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                  <td className="px-6 py-4 ">
                    <div className="flex gap-1">
                      <button type="button" className="w-7 h-7 flex justify-center items-center bg-green-500 hover:bg-green-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Edit" data-target='playlist'
                      onClick={
                        e=>handleBtnModal(e, {
                          target: 'playlist',
                          id: playlist.id,
                        })}
                      ><i className="fa-solid fa-edit" /></button>
                      <button onClick={()=>handleDelete(playlist.id)} type="button" className="w-7 h-7 flex justify-center items-center bg-red-500 hover:bg-red-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Delete"><i className="fa-solid fa-trash" /></button>
                    </div>
                  </td>
                  <td className="px-2 py-4 ">{numberPlaylist++}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{playlist.playlist_name}</td>
                  <td className="px-6 py-4 "><span className={`${playlist.status == 1 ? 'bg-green-500' : playlist.status == 0 ? 'bg-red-500' : 'bg-yellow-500' } text-sm text-white rounded-full px-2 py-0.5`}>{playlist.status == 1 ? 'Active' : playlist.status == 0 ? 'No' : 'New'}</span></td>
                  <td className="px-6 py-4 ">{playlist.required == 1 ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* End Table */}
    </div>
  )
}

export default TablePlaylists