import React from 'react'
import Aside from './Aside'
import logo from '../assets/img/logo-codingasik.png'

const Header = ({handleBarClickOne,bar}) => {

  return (
    <header className={`${bar.header ? 'md:w-[210px]' : 'md:w-[70px]'} bg-black text-white md:fixed w-full md:h-screen border-r border-r-slate-800 relative`}>
      {/* Brand */}
      <div className="flex items-center justify-between w-full h-16 px-4 border-b border-b-slate-800">
        <div className="flex gap-2 items-center">
          <img className="w-8" src={logo} alt="Logo" />
          <h1 className={`${bar.caption && 'hidden'} font-bold text-lg`}>Coding Asik</h1>
        </div>
        <button onClick={handleBarClickOne} className="btn-bar md:hidden" data-index={1}><i className="fa-solid fa-bars" /></button>
      </div>
      {/* End Brand */}
      {/* Menu Aside */}
      <Aside bar={bar}/>
      {/* End Menu Aside */}
    </header>
  )
}

export default Header