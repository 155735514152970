import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../config/Config';

const StatsCourse = ({xToken,slug}) => {
  const [stats, setStats] = useState({
    total_playlists: 0,
    total_lessons: 0,
    total_durations: '00:00:00',
    total_files: 0
  });

  useEffect(()=>{
    fetchCourseStats();
  },[])

  const fetchCourseStats= async()=>{
    
    try {
      const data = await fetchData(`course/${slug}/stats`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });

      setStats(data.data);

    } catch (error) {
      alert(error);
        //console.error('Error: ', error);
    }
  }

  return (
    <div className="mt-8 w-full flex justify-between flex-wrap gap-4">
      <div className="w-[48%] lg:w-[23%] bg-yellow-500 p-4 lg:px-6 rounded-md flex items-center gap-4 text-white">
        <i className="fa-solid fa-play text-3xl" />
        <div>
          <h2 className="text-2xl font-bold">{stats.total_playlists}</h2>
          <p>Playlists</p>
        </div>
      </div>
      <div className="w-[48%] lg:w-[23%] bg-green-500  p-4 lg:px-6 rounded-md flex items-center gap-4 text-white">
        <i className="fa-solid fa-folder text-3xl" />
        <div>
          <h2 className="text-2xl font-bold">{stats.total_files}</h2>
          <p>Files</p>
        </div>
      </div>
      <div className="w-[48%] lg:w-[23%] bg-sky-500  p-4 lg:px-6 rounded-md flex items-center gap-4 text-white">
        <i className="fa-solid fa-video text-3xl" />
        <div>
          <h2 className="text-2xl font-bold">{stats.total_lessons}</h2>
          <p>Lessons</p>
        </div>
      </div>
      <div className="w-[48%] lg:w-[23%] bg-red-500  p-4 lg:px-6 rounded-md flex items-center gap-4 text-white">
        <i className="fa-solid fa-clock text-3xl" />
        <div>
          <h2 className="text-2xl font-bold">{stats.total_durations}</h2>
          <p>Durations</p>
        </div>
      </div>
    </div>
  )
}

export default StatsCourse