import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../config/Config';
import { Link } from 'react-router-dom'
import Pagination from '../../../components/Pagination'

const TableLessons = ({xToken,slug,isActivePanel}) => {

  let [number,setNumber] = useState(1);
  const [lessons, setLessons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //const itemsPerPage = 5; // Use a constant value
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalPages, setTotalPages] = useState(0); // Total pages from API
  const [totalData, setTotalData] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [lastPage, setlastPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');

  useEffect(()=>{
    fetchLessons();
  },[currentPage,searchQuery])

  const fetchLessons = async()=>{
    try {
      const data = await fetchData(`course/${slug}/lessons?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });

      setLessons(data.data.data);
      setTotalPages(data.data.last_page);
      setItemsPerPage(data.data.per_page);
      setTotalData(data.data.total);
      setFrom(data.data.from);
      setTo(data.data.to);
      setlastPage(data.data.last_page);
      setNumber(data.data.from);

    } catch (error) {
      alert(error);
      //console.error('Error: ', error);
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleDelete = async(id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const data = await fetchData(`course/lesson/${id}`,{
          method : 'DELETE',
          credentials: 'include',
          headers : {
            'Authorization': `Bearer ${xToken}`,
          }
        });
        fetchLessons();
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log(`Item with id ${id} not deleted`);
    }
  };

  return (
    <div className={`${isActivePanel.lessons ? '' : 'hidden'} w-full shadow-md p-2 lg:p-4`} data-panel="lessons">
      <div className="flex justify-between items-center gap-2 py-4 px-2">
        <div className="w-1/2">
          <Link to={`/course/lesson/${slug}/add`} title="Add Lesson" className="bg-green-500 text-white rounded-md py-2 px-4 hover:bg-green-600 ease-in-out duration-300"><i className="fa-solid fa-plus mr-2" />Add Lesson</Link>
        </div>
        <div className="flex justify-end w-1/2">
          <div className="flex">
            <input className="px-4 py-2 border-2 border-slate-200  rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 w-full" type="text" name="search" id="search" placeholder="Input keyword" title="Input keyword" onChange={(e)=>setSearch(e.target.value)} required />
            <button onClick={handleSearch} type="button" className="w-12 group "><i className="fa-solid fa-search text-slate-500 group-hover:text-sky-300 ease-in duration-300" title="Search" /></button>
          </div>
        </div>
      </div>
      {/* Table */}
      <div>
        <div className="overflow-y-auto">
          <table className="w-full">
            <thead className="bg-slate-600 text-white h-16 px-4">
              <tr className="text-left">
                <th className="px-6 py-4 ">Action</th>
                <th className="px-2 py-4 ">No</th>
                <th className="px-6 py-4 ">Playlist</th>
                <th className="px-6 py-4 ">Lesson</th>
                <th className="px-6 py-4 ">Url</th>
                <th className="px-6 py-4 ">Duration</th>
                <th className="px-6 py-4 ">Tag</th>
              </tr>
            </thead>
            <tbody>
              { lessons.map(lesson=>(
                <tr key={lesson.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                  <td className="px-6 py-4 ">
                    <div className="flex gap-1">
                      <Link to={`/course/lesson/${slug}/edit/${lesson.id}`} className="w-7 h-7 flex justify-center items-center bg-green-500 hover:bg-green-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Edit"><i className="fa-solid fa-edit" /></Link>
                      <button onClick={()=>handleDelete(lesson.id)} type="button" className="w-7 h-7 flex justify-center items-center bg-red-500 hover:bg-red-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Delete"><i className="fa-solid fa-trash" /></button>
                    </div>
                  </td>
                  <td className="px-2 py-4 ">{number++}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{lesson.playlist_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{lesson.lesson_name}</td>
                  <td className="px-6 py-4 "><a href={lesson.lesson_url} target="_blank" className="text-sky-500 hover:underline" title="Check link">{lesson.lesson_url}</a></td>
                  <td className="px-6 py-4 ">{lesson.duration}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{lesson.tag}</td>
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>
        <div className="mt-6 mb-4 flex flex-col lg:flex-row gap-4 items-center justify-between px-4">
          <p>Show Data : <span id="showData">{from} to {to} of {totalData} Data</span></p>
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            totalPages={totalPages} // Set the total number of pages; for example, if there are 100 items total
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {/* End Table */}
    </div>
  )
}

export default TableLessons