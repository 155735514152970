import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../config/Config';

const Home = ({handleLogout }) => {
  // Mengambil data pengguna dari local storage
  const user = JSON.parse(localStorage.getItem('user'));
  const xToken = localStorage.getItem('xToken');
  const [transactionsStats, setTransactionsStats] = useState({
    cancel: 0,
    pending : 0,
    settlement : 0 
  });

  const navigate = useNavigate();
  useEffect(()=>{
    fetchTransactionsStats()
  },[])
  const fetchTransactionsStats= async()=>{
    try {
      const data = await fetchData(`transactions/stats`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Content-Type' : 'application/json',
          'Authorization' : 'Bearer '+xToken
        }
      })
      setTransactionsStats(data.data);
    } catch (error) {
      alert(error);
    }
  }

  return (

    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Homepage</h1>
      {/* Info Panel */}
      <div className="mt-8 w-full flex justify-between flex-wrap gap-4">
        <div className="w-full md:w-[48%] lg:w-[32%] bg-sky-500 p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-circle-minus text-3xl " />
          <div>
            <h2 className="text-2xl font-bold">{transactionsStats.pending}</h2>
            <p>Pending</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[32%] bg-green-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-circle-check text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{transactionsStats.settlement}</h2>
            <p>Settlement</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[32%] bg-red-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-close text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{transactionsStats.cancel}</h2>
            <p>Cancel</p>
          </div>
        </div>
      </div>
      {/* End Info Panel */}
    </section>
  )
}

export default Home