import React, { useState,useEffect } from 'react'
import { fetchData } from '../../../config/Config';

const ModalFiles = ({slug,currentData,isActiveModal,setIsActiveModal,dataFetch}) => {
  const xToken = localStorage.getItem('xToken');
  const [formData, setFormData] = useState({
    id: '',
    courseSlug: slug,
    fileName: '',
    fileUrl: '',
    note: '',
  })

  useEffect(()=>{
    if(isActiveModal.files == 1 && currentData != ''){
      setFormData({
        id : currentData.id,
        courseSlug: slug,
        fileName : currentData.file_name,
        fileUrl : currentData.file_url,
        note : currentData.note,
      })
    }
      },[currentData])

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formPayload = new FormData();
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    try {
      const data = await fetchData(`course/file`, {
        method: 'POST',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
          'Accept': 'application/json'
        },
        body: formPayload
      });
      if (dataFetch) {
        await dataFetch();
        await setIsActiveModal({files : 0})
      }
    } catch (error) {
      alert(error);
        //console.error('Error: ', error);
    }
  };

  return (
    <div className={`${isActiveModal.files ? '' : 'hidden'} fixed top-0 left-0 w-full h-screen z-10 bg-black bg-opacity-25 flex justify-center items-center`}>
      <div className="bg-white shadow-md p-4 rounded-md relative w-full lg:w-2/6 m-4">
        <button onClick={()=>setIsActiveModal({files : 0})} type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
        <div>
          <h2 className="font-semibold">Add File</h2>
          <hr className="bg-b-slate-200 mt-4" />
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="fileName">File Name</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                type="text"
                name="fileName"
                value={formData.fileName}
                onChange={handleChange}
                placeholder="exp: Belajar Web Design"
                title="Input File Name"
                required />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="fileUrl">File Url</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                type="text"
                name="fileUrl"
                value={formData.fileUrl}
                onChange={handleChange}
                placeholder="exp: https://"
                title="Input File URL"
                required />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="note">File Note</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                type="text"
                name="note"
                value={formData.note}
                onChange={handleChange}
                placeholder="exp: password : ..."
                title="Input Note" />
            </div>
            <hr className="bg-b-slate-200 mt-4" />
            <div className="flex justify-end">
              <button type="submit" className="bg-green-500 hover:bg-green-600 ease-in duration-300 text-white rounded-md px-6 py-2 ">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalFiles