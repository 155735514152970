import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../config/AuthContext';

const Aside = ({bar}) => {
  const { logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };



  return (
    <aside id="menuAside" className={`${bar.aside && 'hidden'} md:block`}>

      <NavLink to="/" className={({ isActive }) => `${isActive ? 'bg-slate-800' : ''} w-full flex gap-2 items-center px-6 h-14 hover:bg-slate-800 transition-colors ease-in-out duration-300` } title="Go to Home Page"><i className="fa-solid fa-home" />
        <div className={bar.caption && 'hidden'}>Home</div>
      </NavLink>

      <h4 className={`${bar.caption && 'hidden'} py-4 px-2 text-sky-600 text-sm`}>Manage</h4>
      
      <NavLink to="/member" className={({ isActive }) => `${isActive ? 'bg-slate-800' : ''} w-full flex gap-2 items-center px-6 h-14 hover:bg-slate-800 transition-colors ease-in-out duration-300` } title="Go to Members Page">
        <i className="fa-solid fa-users" />
        <p className={bar.caption && 'hidden'}>Members</p>
      </NavLink>

      <NavLink to="/course" className={({ isActive }) => `${isActive ? 'bg-slate-800' : ''} w-full flex gap-2 items-center px-6 h-14 hover:bg-slate-800 transition-colors ease-in-out duration-300` } title="Go to Courses Page">
        <i className="fa-solid fa-code" />
        <p className={bar.caption && 'hidden'}>Courses</p>
      </NavLink>

      <h4 className={`${bar.caption && 'hidden'} py-4 px-2 text-sky-600 text-sm`}>Transaction</h4>

      <NavLink to="/transaction" className={({ isActive }) => `${isActive ? 'bg-slate-800' : ''} w-full flex gap-2 items-center px-6 h-14 hover:bg-slate-800 transition-colors ease-in-out duration-300 ` } title="Go to Transactions Page">
        <i className="fa-solid fa-dollar" />
        <p className={bar.caption && 'hidden'}>Transactions</p>
      </NavLink>

      <h4 className={`${bar.caption && 'hidden'} py-4 px-2 text-sky-600 text-sm`}>Feed Back</h4>
      
      <NavLink to="/review" className={({ isActive }) => `${isActive ? 'bg-slate-800' : ''} w-full flex gap-2 items-center px-6 h-14 hover:bg-slate-800 transition-colors ease-in-out duration-300 ` } title="Go to Reviews Page">
        <i className="fa-solid fa-star" />
        <p className={bar.caption && 'hidden'}>Reviews</p>
      </NavLink>

      <button onClick={handleLogout} className="w-full flex gap-2 items-center px-6 h-14 hover:bg-slate-800 transition-colors ease-in-out duration-300 lg:absolute lg:bottom-0 lg:left-0" title="Logout">
        <i className="fa-solid fa-right-from-bracket" />
        <p className={bar.caption && 'hidden'}>Logout</p>
      </button>

    </aside>
  )
}

export default Aside