import React, { useEffect, useState } from 'react'
import {Link, useNavigate, useParams } from 'react-router-dom'
import { fetchData, createSlug } from '../../config/Config'

const CourseLessonAdd = () => {
  const xToken = localStorage.getItem('xToken');
  const { slug,id } = useParams();
  const [playlists, setPlaylist] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    playlistId: '',
    lessonName : '',
    lessonSlug: '',
    lessonUrl: '',
    duration: '',
    description: '',
    tag: '',
    status: '',
    required: '',
  })
  const [currentData, setCurrentData] = useState({});
  useEffect(()=>{
    
    const getData= async()=>{
      if(id){
        await fetchPlaylists();
        await fetchPlaylist();
      }else{
        await fetchPlaylists();
      }  
    }
    getData()

  },[])
  useEffect(()=>{
    if(id){
      if(currentData){
        setFormData({
          id : currentData.id,
          playlistId : currentData.playlist_id,
          lessonName : currentData.lesson_name,
          lessonSlug : currentData.lesson_slug,
          lessonUrl : currentData.lesson_url,
          duration : currentData.duration,
          description : currentData.description,
          tag : currentData.tag,
          status : currentData.status,
          required : currentData.required
        })
      }
    }
  },[currentData])

  const fetchPlaylist=async()=>{
    try {
      const data = await fetchData(`course/${slug}/lesson/${id}`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setCurrentData(data.data);
    } catch (error) {
      alert(error);
    }
  }

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
      ...(name === 'lessonName' && { lessonSlug: createSlug(value) })
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formPayload = new FormData();
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    try {
      const data = await fetchData(`course/lesson`, {
        method: 'POST',
        credentials: 'include', // Penting untuk mengirim cookie httponly
        headers : {
          'Authorization': `Bearer ${xToken}`,
          'Accept': 'application/json'
        },
        body: formPayload
      });
      navigate(`/course/detail/${slug}`);
    } catch (error) {
      console.error('Error adding course:', error);
    }
  };

  

  const fetchPlaylists= async()=>{
    
    try {
      const data = await fetchData(`course/${slug}/playlists`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      
      setPlaylist(data.data);
    } catch (error) {
      alert(error);
    }
  }
  return (
    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Add Lesson</h1>
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">
        <div className="flex justify-between items-center gap-2 py-4 px-2">
          <div className="w-1/2">
            <Link to={`/course/detail/${slug}`} title="Add lesson" className="bg-sky-500 text-white rounded-md py-2 px-4 hover:bg-sky-600 ease-in-out duration-300"><i className="fa-solid fa-arrow-left" /> Back</Link>
          </div>
        </div>
        <hr className="bg-b-slate-200 mt-4" />
        {/* Form */}
        <div className="mt-4 p-4">
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="playlist">Playlist</label>
                <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  name="playlistId"
                  value={formData.playlistId}
                  onChange={handleChange}
                 title="Select Playlist"
                 required>
                  <option value="">--Select Playlists--</option>
                  { playlists.map(playlist=>(
                    <option key={playlist.id} value={playlist.id}>{playlist.playlist_name}</option>

                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="lessonName">Lesson Name</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300"
                  type="text"
                  name="lessonName"
                  value={formData.lessonName}
                  onChange={handleChange}
                  placeholder="exp: Belajar Web Design"
                  title="Input Lesson Name"
                  required />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="lessonUrl">Lesson Url</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="lessonUrl"
                  value={formData.lessonUrl}
                  onChange={handleChange}
                  placeholder="exp: https://"
                  title="Input Lesson Url" />
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="duration">Duration</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  placeholder="00:00:00"
                  title="Input
                  Duration"
                  required />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="tag">Tag</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="tag"
                  value={formData.tag}
                  onChange={handleChange}
                  placeholder="exp: #jwd #jagowebdesign"
                  title="Input Tag"
                  required />
                <span className="text-sm text-red-400">Note : use "#" and separate with "space"</span>
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="description">Description</label>
                <textarea className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={3}
                  placeholder="Input description"
                  title="Input Description"
                  required/>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="status">Status</label>
                <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  title="Select Status"
                  required>
                  <option value>--Select Status--</option>
                  <option value={0}>Not Active</option>
                  <option value={1}>Active</option>
                  <option value={2}>New Lesson</option>
                </select>
                <span className="text-sm text-green-400">Note : is new lesson or not active lesson?</span>
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="required">Is Required?</label>
                <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  name="required"
                  value={formData.required}
                  onChange={handleChange}
                  title="Select Required"
                  required>
                  <option value>--Select Option--</option>
                  <option value={0}>Not Required</option>
                  <option value={1}>Required</option>
                </select>
                <span className="text-sm text-green-400">Note : required for review course to open this lesson</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="courseCode">Course Slug</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="lessonSlug"
                  value={formData.lessonSlug}
                  readOnly />
              </div>
            </div>
            <hr className="bg-b-slate-200 mt-4" />
            <div className="flex justify-end">
              <button type="submit" className="bg-green-500 hover:bg-green-600 ease-in duration-300 text-white rounded-md px-6 py-2 ">Submit</button>
            </div>
          </form>
        </div>
        {/* End Form */}
      </div>
    </section>

  )
}

export default CourseLessonAdd