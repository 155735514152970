//export const BASE_URL= 'http://localhost:8000/api/admin';
export const BASE_URL= 'https://codingasikacademy.com/api/admin';

export function formatRupiah(number) {
  if(number){
    return number.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
    });
  }else{
    return '-'
  }
}

export function createSlug(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Ganti spasi dengan -
    .replace(/[^\w\-]+/g, '') // Hapus semua karakter non-word
    .replace(/\-\-+/g, '-') // Ganti beberapa tanda - dengan satu -
    .replace(/^-+/, '') // Hapus tanda - di awal teks
    .replace(/-+$/, ''); // Hapus tanda - di akhir teks
}

export const fetchData= async(url,options={})=>{
  try {
    const response = await fetch(`${BASE_URL}/${url}`, options);
    
    // Get the value of a specific header
    const xNewToken = response.headers.get('X-New-Token');
  
    if(xNewToken != null){
      localStorage.setItem('xToken',xNewToken);
    }

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    throw error;
  }
}

 // Fungsi untuk menghitung selisih waktu antara dua tanggal
 export const calculateTimeDifference = (dateString) => {
  const currentDate = new Date();
  const pastDate = new Date(dateString);
  
  const years = currentDate.getFullYear() - pastDate.getFullYear();
  const months = currentDate.getMonth() - pastDate.getMonth();
  const days = currentDate.getDate() - pastDate.getDate();

  let totalYears = years;
  let totalMonths = months;
  let totalDays = days;

  // Jika hari negatif, pinjam bulan dari bulan sekarang
  if (totalDays < 0) {
    totalMonths -= 1;
    totalDays += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
  }

  // Jika bulan negatif, pinjam tahun dari tahun sekarang
  if (totalMonths < 0) {
    totalYears -= 1;
    totalMonths += 12;
  }

  if (totalYears > 0) {
    return `${totalYears} years ago`;
  } else if (totalMonths > 0) {
    return `${totalMonths} months ago`;
  } else {
    return `${totalDays} days ago`;
  }
};