import React, { useState, useContext } from 'react';
import { AuthContext } from '../config/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/logo-codingasik.png'
import { fetchData } from '../config/Config';

const Login = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(0);

  const handleShowPassword = e => {
    e.preventDefault();

    setIsShowPassword(!isShowPassword);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(1)
    try {
      const data = await fetchData(`login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      setLoading(0)
      const { xToken, xUser } = data.data;
      login(xToken, xUser);
      navigate('/home');
    } catch (error) {
      alert(error);
      setLoading(0)
    }
  }

  return (
    <main id="viewLogin" className="w-full h-screen flex justify-center items-center ">
      <div className='h-screen bg-cover bg-center -z-20 fixed w-full' style={{ backgroundImage: `url(${'https://images.unsplash.com/photo-1606826995389-47c33af60af9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'})` }}>

      </div>
      <section className="w-full m-4 lg:w-96 bg-white bg-opacity-80 p-8 rounded-md">
        <div className="text-center">
          <div className="flex items-center justify-center gap-4">
            <img className="w-16" src={logo} alt="Logo Coding Asik" />
            <h1 className="text-2xl font-bold text-sky-500">Coding Asik</h1>
          </div>
          <p className="mt-4 text-lg">Sign in to start your session</p>
        </div>
        <hr className="h-1 w-full bg-slate-100 my-4" />
        <form onSubmit={handleLogin} className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <label htmlFor="username">Username</label>
            <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 " type="text" value={username}
              onChange={(e) => setUsername(e.target.value)}
              onFocus={(e) => setError('')}
              name="username" id="username" placeholder="Input username" title="Input Username" required />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="password">Password</label>
            <div className="flex gap-2">
              <input className="px-4 py-2 border-2 border-slate-200  rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 w-full"
                type={`${isShowPassword ? 'text' : 'password'}`} value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => setError('')}
                name="password" id="password" placeholder="Input password" title="Input Password" required />

              <button onClick={handleShowPassword} type="button" className="w-10 group outline-none focus:border-sky-300"><i className={`fa-solid ${isShowPassword ? 'fa-eye' : 'fa-eye-slash'} text-slate-500 group-hover:text-sky-300 ease-in duration-300 `} title="Show Password" /></button>
            </div>
          </div>
          {error && <p className="text-red-400 text-center mt-2">{error}</p>}
          <div className="mt-6">
            <button type="submit" className="bg-sky-500 hover:bg-sky-600 ease-in duration-300 text-white block w-full rounded-md py-2 font-bold outline-none focus:border-sky-300">{loading == 0 ? 'Login' : 'Checking data..'}</button>
          </div>
        </form>
      </section>
    </main>

  )
}

export default Login