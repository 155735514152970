import React from 'react'

const ButtonPanel = ({handleBtnPanel,isActivePanel}) => {
  return (
    <div className="flex gap-2">
      <button data-target="playlists" onClick={handleBtnPanel} type="button" className={`${isActivePanel.playlists ? 'bg-slate-200' : ''} hover:bg-slate-200 shadow-sm py-2 px-4 rounded-md`} >Playlists</button>
      <button data-target="files" onClick={handleBtnPanel} type="button" className={`${isActivePanel.files ? 'bg-slate-200' : ''} hover:bg-slate-200 shadow-sm py-2 px-4 rounded-md`}>Files</button>
      <button data-target="lessons" onClick={handleBtnPanel} type="button" className={`${isActivePanel.lessons ? 'bg-slate-200' : ''} hover:bg-slate-200 shadow-sm py-2 px-4 rounded-md`}>Lessons</button>
    </div>
  )
}

export default ButtonPanel