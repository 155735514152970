import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../config/Config';

const TableFiles = ({xToken,slug,isActivePanel,handleBtnModal,dataFetch}) => {
  
  const [files, setFiles] = useState([]);
  let numberFiles = 1;

  useEffect(()=>{
    fetchFiles();
    dataFetch(fetchFiles)
  },[])

  const fetchFiles= async()=>{
    try {
      const data = await fetchData(`course/${slug}/files`,{
        method : 'GET',
        credentials: 'include', // Penting untuk mengirim cookie httponly
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });

      setFiles(data.data);

    } catch (error) {
      alert(error);
        //console.error('Error: ', error);
    }
  }

  const handleDelete = async(id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const data = await fetchData(`course/file/${id}`,{
          method : 'DELETE',
          credentials: 'include', // Penting untuk mengirim cookie httponly
          headers : {
            'Authorization': `Bearer ${xToken}`,
          }
        });

        fetchFiles();

      } catch (error) {
        alert(error);
          //console.error('Error: ', error);
      }
    } else {
      console.log(`Item with id ${id} not deleted`);
    }
  };

  return (
    <div className={`${isActivePanel.files ? '' : 'hidden'} w-full shadow-md p-2 lg:p-4`} data-panel="files">
      <div className="flex justify-between items-center gap-2 py-4 px-2">
        <div className="w-1/2">
          <button data-target="files" onClick={(e) =>
                handleBtnModal(e, { target: 'file'})
              } type="button" title="Add file" className="bg-green-500 text-white rounded-md py-2 px-4 hover:bg-green-600 ease-in-out duration-300"><i className="fa-solid fa-plus mr-2" />Add File</button>
        </div>
      </div>
      {/* Table */}
      <div>
        <div className="overflow-y-auto">
          <table className="w-full">
            <thead className="bg-slate-600 text-white h-16 px-4">
              <tr className="text-left">
                <th className="px-6 py-4 ">Action</th>
                <th className="px-2 py-4 ">No</th>
                <th className="px-6 py-4 whitespace-nowrap">Files</th>
                <th className="px-6 py-4 ">Url</th>
                <th className="px-6 py-4 ">Note</th>
              </tr>
            </thead>
            <tbody>
              { files.map(file=>(
                <tr key={file.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                  <td className="px-6 py-4 ">
                    <div className="flex gap-1">
                      <button type="button" className="w-7 h-7 flex justify-center items-center bg-green-500 hover:bg-green-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Edit"
                      onClick={
                        e=>handleBtnModal(e, {
                          target: 'file',
                          id: file.id,
                        })}
                        ><i className="fa-solid fa-edit" /></button>
                      <button onClick={()=>handleDelete(file.id)} type="button" className="w-7 h-7 flex justify-center items-center bg-red-500 hover:bg-red-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Delete"><i className="fa-solid fa-trash" /></button>
                    </div>
                  </td>
                  <td className="px-2 py-4 ">{numberFiles++}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{file.file_name}</td>
                  <td className="px-6 py-4 "><a href={file.file_url} target="_blank" className="text-sky-500 hover:underline" title="Check link">{file.file_url}</a></td>
                  <td className="px-6 py-4 ">{file.note}</td>
                </tr>
              )) }
              
            </tbody>
          </table>
        </div>
      </div>
      {/* End Table */}
    </div>
  )
}

export default TableFiles