import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { fetchData } from '../../config/Config'
import Pagination from '../../components/Pagination'

const Member = () => {
  const [members, setMembers] = useState([]);
  const [stats, setStats] = useState({
    male : 0,
    female : 0,
    active : 0,
    prospect :0
  });
  const [currentPage, setCurrentPage] = useState(1);
  //const itemsPerPage = 5; // Use a constant value
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalPages, setTotalPages] = useState(0); // Total pages from API
  const [totalData, setTotalData] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [lastPage, setlastPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  let [number,setNumber] = useState(1);
  const xToken = localStorage.getItem('xToken');

  useEffect(()=>{
    fetchMembers();
  },[currentPage,searchQuery])

  useEffect(()=>{
    fetchStats();
  },[])

  const fetchMembers= async()=>{
    
    try {
      const data = await fetchData(`members?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });

      setMembers(data.data.data);
      setTotalPages(data.data.last_page);
      setItemsPerPage(data.data.per_page);
      setTotalData(data.data.total);
      setFrom(data.data.from);
      setTo(data.data.to);
      setlastPage(data.data.last_page);
      setNumber(data.data.from);

    } catch (error) {
      alert(error);
    }
  }

  const fetchStats= async()=>{
    try {
      const data = await fetchData(`members/stats`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setStats(data.data);
    } catch (error) {
      alert(error);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setCurrentPage(1); // Reset to first page on new search
  };

   // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (

    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Manage Members</h1>
      {/* Info Panel */}
      <div className="mt-8 w-full flex justify-between flex-wrap gap-4">
        <div className="w-full md:w-[48%] lg:w-[23%] bg-yellow-500 p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-diagram-project text-3xl " />
          <div>
            <h2 className="text-2xl font-bold">{stats.prospect}</h2>
            <p>Prospect</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[23%] bg-green-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-users text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{stats.active}</h2>
            <p>Members</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[23%] bg-sky-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-mars text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{stats.male}</h2>
            <p>Member Man</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[23%] bg-pink-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-venus text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{stats.female}</h2>
            <p>Member Woman</p>
          </div>
        </div>
      </div>
      {/* End Info Panel */}
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">
        <div className="flex justify-between items-center gap-2 py-4 px-2">
          <div className="flex justify-end w-full">
            <div className="flex">
              <input className="px-4 py-2 border-2 border-slate-200  rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 w-full" type="text" name="search" id="search" placeholder="Input keyword" title="Input keyword" onChange={(e)=>setSearch(e.target.value)} required />
              <button onClick={handleSearch} type="button" className="w-12 group "><i className="fa-solid fa-search text-slate-500 group-hover:text-sky-300 ease-in duration-300" title="Search" /></button>
            </div>
          </div>
        </div>
        {/* Table */}
        <div>
          <div className="overflow-y-auto">
            <table className="w-full">
              <thead className="bg-slate-600 text-white h-16 px-4">
                <tr>
                  <th className="px-6 py-4 ">Action</th>
                  <th className="px-2 py-4 ">No</th>
                  <th className="px-6 py-4 ">Active</th>
                  <th className="px-6 py-4 ">Picture</th>
                  <th className="px-6 py-4 ">Name</th>
                  <th className="px-6 py-4 ">Gender</th>
                  <th className="px-6 py-4 ">username</th>
                  <th className="px-6 py-4 ">Email</th>
                  <th className="px-6 py-4 ">Whatsapp</th>
                </tr>
              </thead>
              <tbody>
                
                {/* <tr >
                  <td>{member.name}</td>
                  <td>{member.email}</td>
                </tr> */}
              {members.map(member => (
                <tr key={member.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                <td className="px-6 py-4 ">
                  <div className="flex gap-1">
                    <Link to={`/member/detail/${member.username}`} className="w-7 h-7 flex justify-center items-center bg-sky-500 hover:bg-sky-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Detail"><i className="fa-solid fa-eye" /></Link>
                  </div>
                </td>
                <td className="px-2 py-4 ">{number++}</td>
                <td className="px-6 py-4 "><span className={`text-sm  rounded-full px-2 py-0.5 text-white ${member.active == 1 ? 'bg-green-500 ' : 'bg-yellow-500'}`}>{member.active == 1 ? 'Active' : 'Pending'}</span></td>
                <td className="px-6 py-4 "><img className="w-8 h-8 rounded-full border border-slate-300" src={member.picture} alt={`${member.first_name} ${member.last_name}`} /></td>
                <td className="px-6 py-4 whitespace-nowrap">{`${member.first_name} ${member.last_name}`}</td>
                <td className="px-6 py-4 "><i className={`fa-solid ${member.gender == 'f' ? 'fa-venus text-pink-400' : 'fa-mars text-sky-400'} `} /></td>
                <td className="px-6 py-4 ">{member.username}</td>
                <td className="px-6 py-4 ">{member.email}</td>
                <td className="px-6 py-4 "><a className="text-sky-400 hover:underline" href={`https://wa.me/${member.whatsapp}`} target="_blank" title="Click for whatsapp">{member.whatsapp}</a></td>
              </tr>
              ))}
                
              </tbody>
            </table>
          </div>
          <div className="mt-6 mb-4 flex flex-col lg:flex-row gap-4 items-center justify-between px-4">
            <p>Show Data : <span id="showData">{from} to {to} of {totalData} Data</span></p>
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            
          </div>
        </div>
        {/* End Table */}
      </div>
    </section>
  )
}

export default Member