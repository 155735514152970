// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

// Membuat context
export const AuthContext = createContext();

// Membuat provider untuk context
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    xToken: localStorage.getItem('xToken'),
    xUser: JSON.parse(localStorage.getItem('xUser')),
  });

  useEffect(() => {
    const xToken = localStorage.getItem('xToken');
    const xUser = JSON.parse(localStorage.getItem('xUser'));

    if (xToken && xUser) {
      setAuth({ xToken, xUser });
    }
    
  }, []);

  const login = (xToken, xUser) => {
    localStorage.setItem('xToken', xToken);
    localStorage.setItem('xUser', JSON.stringify(xUser));
    setAuth({ xToken, xUser });
  };

  const logout = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/api/auth/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.xToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      localStorage.removeItem('xToken');
      localStorage.removeItem('xUser');
      setAuth({ xToken: null, xUser: null });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
