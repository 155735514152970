import React, { useState } from 'react'
import { fetchData } from '../../../config/Config';

const ModalSendNotif = ({isActiveModalNotif,setIsActiveModalNotif}) => {
  const xToken = localStorage.getItem('xToken');
  const [formData, setFormData] = useState({
    email : '',
    transactionCode : ''
  })
  const [loading,setLoading] = useState(0);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formPayload = new FormData();
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    try {
      const data = await fetchData(`transaction/notif`, {
        method: 'POST',
        credentials: 'include', // Penting untuk mengirim cookie httponly
        headers : {
          'Authorization': `Bearer ${xToken}`,
          'Accept': 'application/json'
        },
        body: formPayload
      });
      setLoading(0)
      console.log(data)
      setIsActiveModalNotif(0);
      if(data.status){
        alert(data.message);
        setFormData({
          email : '',
          transactionCode : '',
        });
      }
    } catch (error) {
      setLoading(0)
      alert(error);
        //console.error('Error: ', error);
    }
  };

  return (
    <div id="modalPlaylist" className={`${isActiveModalNotif ? '' : 'hidden'} fixed top-0 left-0 w-full h-screen z-10 bg-black bg-opacity-25 flex justify-center items-center`}>
      <div className="bg-white shadow-md p-4 rounded-md relative w-full lg:w-2/6 m-4">
        <button onClick={()=>setIsActiveModalNotif(0)} type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
        <div>
          <h2 className="font-semibold">Send Notif</h2>
          <hr className="bg-b-slate-200 mt-4" />
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="email">Email</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 " type="email"
              value={formData.email}
              onChange={handleChange}
              name="email"  placeholder="exp: Input email" title="Input email" required />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="transactionCode">Transaction Code</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 " type="text"
              value={formData.transactionCode}
              onChange={handleChange}
              name="transactionCode"  placeholder="exp: Input transaction code" title="Input transaction code" required />
            </div>
            <hr className="bg-b-slate-200 mt-4" />
            <div className="flex justify-end">
              <button onClick={()=>setLoading(1)} type="submit" className="bg-green-500 hover:bg-green-600 ease-in duration-300 text-white rounded-md px-6 py-2 ">{loading ? 'Sending email..' : 'Send'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}

export default ModalSendNotif