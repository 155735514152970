import { useEffect, useState } from 'react'
import { formatRupiah,fetchData } from '../../config/Config'
import Pagination from '../../components/Pagination'
import { Link } from 'react-router-dom'

const Course = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //const itemsPerPage = 5; // Use a constant value
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalPages, setTotalPages] = useState(0); // Total pages from API
  const [totalData, setTotalData] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  let [number,setNumber] = useState(1);
  const xToken = localStorage.getItem('xToken');

  useEffect(()=>{
    fetchCourses();
  },[currentPage,searchQuery])

  const fetchCourses= async()=>{
    
    try {
      const data = await fetchData(`courses?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`,{
        method : 'GET',
        credentials: 'include', // Penting untuk mengirim cookie httponly
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      })

      setCourses(data.data.data);
      setTotalPages(data.data.last_page);
      setItemsPerPage(data.data.per_page);
      setTotalData(data.data.total);
      setFrom(data.data.from);
      setTo(data.data.to);
      setNumber(data.data.from);
    } catch (error) {
      alert(error);
        //console.error('Error: ', error);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setCurrentPage(1); // Reset to first page on new search
  };

   // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async(id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const data = await fetchData(`course/${id}`,{
          method : 'DELETE',
          credentials: 'include', // Penting untuk mengirim cookie httponly
          headers : {
            'Authorization': `Bearer ${xToken}`,
          }
        });

        fetchCourses();

      } catch (error) {
        alert(error);
        //console.error('Error: ', error);
      }
    } else {
      console.log(`Item with id ${id} not deleted`);
    }
    
  };

  return (

    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Manage Courses</h1>
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">
        <div className="flex justify-between items-center gap-2 py-4 px-2">
          <div className="w-1/2">
            <Link to="/course/add" title="Add course" className="bg-green-500 text-white rounded-md py-2 px-4 hover:bg-green-600 ease-in-out duration-300"><i className="fa-solid fa-plus mr-2" /> Add course</Link>
          </div>
          <div className="flex justify-end w-1/2">
            <div className="flex">
              <input className="px-4 py-2 border-2 border-slate-200  rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 w-full" type="text" name="search" id="search" placeholder="Input keyword" title="Input keyword" onChange={(e)=>setSearch(e.target.value)} required />
              <button onClick={handleSearch} type="button" className="w-12 group "><i className="fa-solid fa-search text-slate-500 group-hover:text-sky-300 ease-in duration-300" title="Search" /></button>
            </div>
          </div>
        </div>
        {/* Table */}
        <div>
          <div className="overflow-y-auto">
            <table className="w-full">
              <thead className="bg-slate-600 text-white h-16 px-4">
                <tr>
                  <th className="px-6 py-4 ">Action</th>
                  <th className="px-2 py-4 ">No</th>
                  <th className="px-6 py-4 ">Kode</th>
                  <th className="px-6 py-4 ">Name</th>
                  <th className="px-6 py-4 whitespace-nowrap">Price (Rp)</th>
                  <th className="px-6 py-4 ">Playlist</th>
                  <th className="px-6 py-4 ">Video</th>
                  <th className="px-6 py-4 ">Duration</th>
                  <th className="px-6 py-4 ">Files</th>
                  <th className="px-6 py-4 ">Tag</th>
                </tr>
              </thead>
              <tbody>
                { courses.map(course=>(
                  <tr key={course.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                    <td className="px-6 py-4 ">
                      <div className="flex gap-1">
                        <Link to={`/course/edit/${course.id}`} className="w-7 h-7 flex justify-center items-center bg-green-500 hover:bg-green-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Edit"><i className="fa-solid fa-edit" /></Link>
                        <button onClick={()=>handleDelete(course.id)} type="button" className="w-7 h-7 flex justify-center items-center bg-red-500 hover:bg-red-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Delete"><i className="fa-solid fa-trash" /></button>
                        <Link to={`/course/detail/${course.course_slug}`} type="button" className="w-7 h-7 flex justify-center items-center bg-sky-500 hover:bg-sky-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Detail"><i className="fa-solid fa-eye" /></Link>
                      </div>
                    </td>
                    <td className="px-2 py-4 ">{number++}</td>
                    <td className="px-6 py-4 ">{course.course_code}</td>
                    <td className="px-6 py-4 ">{course.course_name}</td>
                    <td className="px-6 py-4 ">{formatRupiah(course.price)}</td>
                    <td className="px-6 py-4 ">{course.total_playlists}</td>
                    <td className="px-6 py-4 ">{course.total_lessons}</td>
                    <td className="px-6 py-4 ">{course.total_duration}</td>
                    <td className="px-6 py-4 ">{course.total_files}</td>
                    <td className="px-6 py-4 ">{course.tag}</td>
                  </tr>
                ))}
                
              </tbody>
            </table>
          </div>
          <div className="mt-6 mb-4 flex flex-col lg:flex-row gap-4 items-center justify-between px-4">
            <p>Show Data : <span id="showData">{from} to {to} of {totalData} Data</span></p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages} // Set the total number of pages; for example, if there are 100 items total
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* End Table */}
      </div>
    </section>
  )
}

export default Course