import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../config/AuthContext';

const Navbar = ({handleBarClickTwo}) => {

  const { logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };
  return (
    <nav className="text-slate-500 justify-between items-center w-full h-16 px-4 lg:px-8 bg-black hidden md:flex">
      <button onClick={handleBarClickTwo} className="btn-bar w-8 h-8 flex justify-center items-center" data-index={2}><i className="fa-solid fa-bars" /></button>
      <button onClick={handleLogout} title="Logout" className="w-8 h-8 flex justify-center items-center"><i className="fa-solid fa-right-from-bracket" /></button>
    </nav>
  )
}

export default Navbar