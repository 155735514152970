import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Header from './Header'
import Navbar from './Navbar'

const Layout = () => {
  const [bar,setBar] = useState({
    header : 1,
    aside : 1,
    main : 1,
    caption : 0
  })
  
  const handleBarClickOne=()=>{
    bar.aside ? setBar({...bar,aside:0}) : setBar({...bar,aside:1})
  }

  const handleBarClickTwo=()=>{
    bar.header ? setBar({
      ...bar,
      header:0,
      main:0,
      caption : 1
    }) : setBar({
      ...bar,
      header:1,
      main:1,
      caption : 0
    })
  }

  return (
    <div>
      <Header handleBarClickOne={handleBarClickOne} bar={bar} />
      <main  id="main" className={`${bar.main ? 'md:ml-[210px]' : 'md:ml-[70px]'}`}>
        {/* Navbar */}
        <Navbar handleBarClickTwo={handleBarClickTwo}/>
        {/* End Navbar */}
        {/* Content */}
        <Outlet />
        {/* End Content */}
      </main>

    </div>
  )
}

export default Layout