import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { formatRupiah,fetchData } from "../../config/Config";
import Pagination from "../../components/Pagination";
import ModalSendNotif from "./components/ModalSendNotif";

const Transaction = () => {
  const xToken = localStorage.getItem('xToken');
  const [transactions, setTransactions] = useState([]);
  const [transactionsStats, setTransactionsStats] = useState({
    cancel: 0,
    pending : 0,
    settlement : 0 
  });
  const [currentPage, setCurrentPage] = useState(1);
  //const itemsPerPage = 5; // Use a constant value
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalPages, setTotalPages] = useState(0); // Total pages from API
  const [totalData, setTotalData] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [lastPage, setlastPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  let [number,setNumber] = useState(1);
  const [isActiveModalNotif, setIsActiveModalNotif] = useState(0);

  useEffect(()=>{
    fetchTransactionsStats()
  },[])

  const fetchTransactionsStats= async()=>{
    try {
      const data = await fetchData(`transactions/stats`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Content-Type' : 'application/json',
          'Authorization' : 'Bearer '+xToken
        }
      })
      setTransactionsStats(data.data);
    } catch (error) {
      alert(error);
    }
  }
  useEffect(()=>{
    fetchTransactions()
  },[currentPage,searchQuery])

  const fetchTransactions= async()=>{
    try {
      const data = await fetchData(`transactions?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Content-Type' : 'application/json',
          'Authorization' : 'Bearer '+xToken
        }
      })
      setTransactions(data.data.data);
      setTotalPages(data.data.last_page);
      setItemsPerPage(data.data.per_page);
      setTotalData(data.data.total);
      setFrom(data.data.from);
      setTo(data.data.to);
      setlastPage(data.data.last_page);
      setNumber(data.data.from);
    } catch (error) {
      alert(error);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setCurrentPage(1); // Reset to first page on new search
  };

   // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowModal=(e)=>{
    e.preventDefault();
    setIsActiveModalNotif(1)
  }

  return (

    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Manage Transactions</h1>
      {/* Info Panel */}
      <div className="mt-8 w-full flex justify-between flex-wrap gap-4">
        <div className="w-full md:w-[48%] lg:w-[32%] bg-sky-500 p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-circle-minus text-3xl " />
          <div>
            <h2 className="text-2xl font-bold">{transactionsStats.pending}</h2>
            <p>Pending</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[32%] bg-green-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-circle-check text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{transactionsStats.settlement}</h2>
            <p>Settlement</p>
          </div>
        </div>
        <div className="w-full md:w-[48%] lg:w-[32%] bg-red-500  p-4 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <i className="fa-solid fa-close text-3xl" />
          <div>
            <h2 className="text-2xl font-bold">{transactionsStats.cancel}</h2>
            <p>Cancel</p>
          </div>
        </div>
      </div>
      {/* End Info Panel */}
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">
        <div className="flex justify-between items-center gap-2 py-4 px-2">
          <div className="w-1/2">
            <button onClick={handleShowModal} type="button" title="Add member" className="bg-green-500 text-white rounded-md py-2 px-4 hover:bg-green-600 ease-in-out duration-300"><i className="fa-solid fa-envelope mr-2" /> Send Notif</button>
          </div>
          <div className="flex justify-end w-1/2">
            <div className="flex">
              <input className="px-4 py-2 border-2 border-slate-200  rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 w-full" type="text" name="search" id="search" placeholder="Input keyword" title="Input keyword" onChange={(e)=>setSearch(e.target.value)} required  />
              <button onClick={handleSearch} type="button" className="w-12 group "><i className="fa-solid fa-search text-slate-500 group-hover:text-sky-300 ease-in duration-300" title="Search" /></button>
            </div>
          </div>
        </div>
        {/* Table */}
        <div>
          <div className="overflow-y-auto">
            <table className="w-full">
              <thead className="bg-slate-600 text-white h-16 px-4">
                <tr>
                  <th className="px-6 py-4 ">Action</th>
                  <th className="px-2 py-4 ">No</th>
                  <th className="px-6 py-4 ">Status</th>
                  <th className="px-6 py-4 ">Code</th>
                  <th className="px-6 py-4 ">Order At</th>
                  <th className="px-6 py-4 ">Paid At</th>
                  <th className="px-6 py-4 ">Username</th>
                  <th className="px-6 py-4 ">Email</th>
                  <th className="px-6 py-4 whitespace-nowrap">Gross Amount</th>
                  <th className="px-6 py-4 whitespace-nowrap">Payment Type</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map(transaction=>(
                  <tr key={transaction.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                    <td className="px-6 py-4 ">
                      <div className="flex gap-1">
                        <Link to={`/transaction/detail/${transaction.id}`} className="w-7 h-7 flex justify-center items-center bg-sky-500 hover:bg-sky-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Detail"><i className="fa-solid fa-eye" /></Link>
                        {/* <button type="button" className="w-7 h-7 flex justify-center items-center bg-green-500 hover:bg-green-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Edit"><i className="fa-solid fa-edit" /></button>
                        <button type="button" className="w-7 h-7 flex justify-center items-center bg-red-500 hover:bg-red-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Cancel"><i className="fa-solid fa-close" /></button> */}
                      </div>
                    </td>
                    <td className="px-2 py-4 ">{number++}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.transaction_status}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.transaction_code}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.transaction_time}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.paid_at}</td>
                    <td className="px-6 py-4 whitespace-nowrap"><Link className="text-sky-400 hover:underline" title="Detail User" to={`/member/detail/${transaction.username}`}>{transaction.username}</Link></td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap"> {formatRupiah(transaction.gross_amount)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.payment_type}</td>
                  </tr>
                ))}
                
              </tbody>
            </table>
          </div>
          <div className="mt-6 mb-4 flex flex-col lg:flex-row gap-4 items-center justify-between px-4">
            <p>Show Data : <span id="showData">{from} to {to} of {totalData} Data</span></p>
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              totalPages={totalPages} // Set the total number of pages; for example, if there are 100 items total
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* End Table */}
      </div>
      <ModalSendNotif isActiveModalNotif={isActiveModalNotif} setIsActiveModalNotif={setIsActiveModalNotif}/>
    </section>
  )
}

export default Transaction