import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,createSlug } from '../../config/Config'
import { Link } from 'react-router-dom'

const CourseAdd = () => {
  const {id} = useParams();
  const [formData, setFormData] = useState({
    id: '',
    courseCode: '',
    courseName: '',
    courseSlug : '',
    price: '',
    reviewUrl: '',
    thumbnail: null,
    thumbnailPreview: '',
    tag: '',
    description: '',
    certificateCaption: '',
  })
  const xToken = localStorage.getItem('xToken')
  const [currentData, setCurrentData] = useState({})

  useEffect(()=>{
    fetchCourse();
  },[])
  useEffect(()=>{
    if(id){
      if(currentData){
        setFormData({
          id : currentData.id,
          courseCode: currentData.course_code,
          courseName: currentData.course_name,
          courseSlug : currentData.course_slug,
          price: currentData.price,
          reviewUrl: currentData.review_url,
          thumbnail: currentData.thumbnail,
          thumbnailPreview: currentData.thumbnail,
          tag: currentData.tag,
          description: currentData.description,
          certificateCaption: currentData.certificate_caption,
        })
      }
    }
  },[currentData])

  const fetchCourse= async()=>{
    try {
      const data = await fetchData(`course/${id}`,{
        method : 'GET',
        credentials: 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      console.log(data.data);
      setCurrentData(data.data)
    } catch (error) {
      alert(error);
    }
  }

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
      ...(name === 'courseName' && { courseSlug: createSlug(value) })
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formPayload = new FormData();
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    try {
      const data = await fetchData(`courses`, {
        method: 'POST',
        credentials: 'include', // Penting untuk mengirim cookie httponly
        headers : {
          'Authorization': `Bearer ${xToken}`,
          'Accept': 'application/json'
        },
        body: formPayload
      });
      navigate('/course');
    } catch (error) {
      alert(error);
        //console.error('Error: ', error);
    }
  };

  return (
    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Add Course</h1>
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">
        <div className="flex justify-between items-center gap-2 py-4 px-2">
          <div className="w-1/2">
            <Link to={'/course'} type="button" title="Add course" className="bg-sky-500 text-white rounded-md py-2 px-4 hover:bg-sky-600 ease-in-out duration-300"><i className="fa-solid fa-arrow-left" /> Back</Link>
          </div>
        </div>
        <hr className="bg-b-slate-200 mt-4" />
        {/* Form */}
        <div className="mt-4 p-4">
          <form onSubmit={handleSubmit} encType="multipart/form-data" className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="courseCode">Course Code</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="courseCode"
                  value={formData.courseCode}
                  onChange={handleChange}
                  placeholder="exp: BW001"
                  title="Input Course Code"
                  required />
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="courseName">Course Name</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="courseName"
                  value={formData.courseName}
                  onChange={handleChange}
                  placeholder="exp: Jago Web Design"
                  title="Input Course Name"
                  required />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="price">Price</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  placeholder="exp: 150000"
                  title="Input Price"
                  required />
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="reviewUrl">Review Url</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="url"
                  name="reviewUrl"
                  value={formData.reviewUrl}
                  onChange={handleChange}
                  placeholder="exp: https://"
                  title="Input Review Url" />
              </div>
            </div>
            <div className={`${!currentData ? 'hidden' : !formData.thumbnailPreview ? 'hidden': ''} w-full flex justify-center mb-2`}>
              <img className="w-full p-1 border-2 border-sky-400" src={'http://localhost:8000/storage/'+formData.thumbnailPreview} alt="Andra Coding" />
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="thumbnail">Thumbnail</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="file"
                  name="thumbnail"
                  onChange={handleChange}
                  title="Select thumbnail"
                  required />
                <span className="text-sm text-red-400">Note : file size must be &lt; 300Kb with resolution 3:2</span>
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="tag">Tag</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="tag"
                  value={formData.tag}
                  onChange={handleChange}
                  placeholder="exp: #jwd #jagowebdesign"
                  title="Input Tag"
                  required />
                <span className="text-sm text-red-400">Note : use "#" and separate with "space"</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="description">Description</label>
                <textarea className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={3}
                  placeholder="Input description"
                  title="Input Description"
                  required
                  />
              </div>
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="certificateCaption">Certificate Caption</label>
                <textarea className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300"
                  name="certificateCaption"
                  value={formData.certificateCaption}
                  onChange={handleChange}
                  rows={3}
                  placeholder="Input certificate caption"
                  title="Input Certificate Caption"
                  required />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full lg:w-1/2">
                <label htmlFor="courseCode">Course Slug</label>
                <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                  type="text"
                  name="courseSlug"
                  value={formData.courseSlug}
                  readOnly />
              </div>
            </div>
            <hr className="bg-b-slate-200 mt-4" />
            <div className="flex justify-end">
              <button type="submit" className="bg-green-500 hover:bg-green-600 ease-in duration-300 text-white rounded-md px-6 py-2 ">Submit</button>
            </div>
          </form>
        </div>
        {/* End Form */}
      </div>
    </section>

  )
}

export default CourseAdd