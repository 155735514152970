import React, { useState,useEffect } from 'react'
import { fetchData } from '../../../config/Config';

const ModalPlaylists = ({slug,currentData,isActiveModal,setIsActiveModal,dataFetch}) => {
  const xToken = localStorage.getItem('xToken');
  const [formData, setFormData] = useState({
    id: '',
    courseSlug: slug,
    playlistName: '',
    status: '',
    required: '',
  })

  useEffect(()=>{
if(isActiveModal.playlists == 1 && currentData != ''){
  setFormData({
    id : currentData.id,
    playlistName : currentData.playlist_name,
    courseSlug : slug,
    status : currentData.status,
    required : currentData.required
  })
}
  },[currentData])

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formPayload = new FormData();
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    try {
      const data = await fetchData(`course/playlist`, {
        method: 'POST',
        credentials: 'include', // Penting untuk mengirim cookie httponly
        headers : {
          'Authorization': `Bearer ${xToken}`,
          'Accept': 'application/json'
        },
        body: formPayload
      });

      if (dataFetch) {
        await dataFetch();
        await setIsActiveModal({playlists : 0})
      }
    } catch (error) {
      alert(error);
        //console.error('Error: ', error);
    }
  };

  return (
    <div className={`${isActiveModal.playlists ? '' : 'hidden'} fixed top-0 left-0 w-full h-screen z-10 bg-black bg-opacity-25 flex justify-center items-center`} >
      <div className="bg-white shadow-md p-4 rounded-md relative w-full lg:w-2/6 m-4">
        <button onClick={()=>setIsActiveModal({playlists : 0})}  type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
        <div>
          <h2 className="font-semibold">Add Playlist</h2>
          <hr className="bg-b-slate-200 mt-4" />
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
            <input
            name="id"
            type="hidden"
            value={formData.id}
            />
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="playlistName">Playlist Name</label>
              <input className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                type="text"
                name="playlistName"
                value={formData.playlistName}
                onChange={handleChange}
                placeholder="exp: Belajar Web Design"
                title="Input Playlist Name"
                required />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="status">Status</label>
              <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 " 
                name="status"
                value={formData.status}
                onChange={handleChange}
                title="Select Status"
                required>
                <option value>--Select Status--</option>
                <option value={0}>Not Active</option>
                <option value={1}>Active</option>
                <option value={2}>New Lesson</option>
              </select>
              <span className="text-sm text-green-400">Note : is new lesson or not active lesson?</span>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="required">Is Required?</label>
              <select className="px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300 "
                name="required"
                value={formData.required}
                onChange={handleChange}
                title="Select Required"
                required>
                <option value>--Select Option--</option>
                <option value={0}>Not Required</option>
                <option value={1}>Required</option>
              </select>
              <span className="text-sm text-green-400">Note : required for review course to open this lesson</span>
            </div>
            <hr className="bg-b-slate-200 mt-4" />
            <div className="flex justify-end">
              <button type="submit" className="bg-green-500 hover:bg-green-600 ease-in duration-300 text-white rounded-md px-6 py-2 ">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalPlaylists