import React, { useEffect, useState } from 'react'
import { fetchData } from '../../config/Config'
import { useParams } from 'react-router-dom'

const MemberDetail = () => {
  const { username } = useParams();
  const [member,setMember] = useState({});
  const [memberCourses,setMemberCourses] = useState([]);
  const [memberCourseProgress,setMemberCourseProgress] = useState([]);
  const xToken = localStorage.getItem('xToken');
  const [panelProfiles, setPanelProfiles] = useState(1);
  const [panelCourses, setPanelCourses] = useState(0);
  const [activeModal, setActiveModal] = useState(0);
  const [memberId, setMemberId] = useState('');
  const [discord, setDiscord] = useState(null);
  const [isActiveFormDiscord, setIsActiveFormDiscord] = useState(false);
  let number =1;

  const handlePanelProfiles =e=>{
    e.preventDefault();
    if(panelProfiles == 1){
      setPanelProfiles(0);
    }else{
      setPanelProfiles(1);
    }
  }
  const handlePanelCourses =e=>{
    e.preventDefault();
    if(panelCourses == 0){
      setPanelCourses(1);
    }else{
      setPanelCourses(0);
    }

    if(memberCourses == '' || memberCourses == null){
      fetchMemberCourses();
    }

  }

  useEffect(()=>{
    fetchMember();
  },[])

  const fetchMember = async()=>{
    try {
      const data = await fetchData(`member/detail/${username}`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setMember(data.data);
      setMemberId(member.id);
      setDiscord(member.discord);
      
    } catch (error) {
      alert(error);
    }
  }

  const fetchMemberCourses = async()=>{
    try {
      const data = await fetchData(`members/${username}/courses`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setMemberCourses(data.data);
      
    } catch (error) {
      alert(error);
    }
  }

  const handleModalProgress=async(id)=>{
    if(activeModal == 0){
      setActiveModal(1);
    }else{
      setActiveModal(0);
    }
    await fetchMemberCourseProgress(id);
  }

  const fetchMemberCourseProgress = async(id)=>{
    try {
      const data = await fetchData(`members/${username}/courses/${id}/details`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setMemberCourseProgress(data.data);
    } catch (error) {
      alert(error);
    }
  }

  const handleBtnFormDiscord =()=>{
    if(discord == '' || discord == null){
      if(isActiveFormDiscord){
        setIsActiveFormDiscord(false);
      }else{
        setIsActiveFormDiscord(true);
      }
    }
  }

  const handleDiscordSubmit =(e)=>{
    e.preventDefault();
    if(discord != null || discord != ''){
      fetchDiscordUpdate();
    }
  }

  const fetchDiscordUpdate = async()=>{
    try {
      const data = await fetchData(`member/${memberId}`,{
        method : 'POST',
        headers : {
          'Authorization': `Bearer ${xToken}`,
          'Content-type' : 'application/json'
        },
        body : JSON.stringify({
          'discord' : discord
        })
      });
      
      
    } catch (error) {
      alert(error);
    }
  }

  return (
    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Member Details</h1>
      <div className="mt-8 flex flex-col lg:flex-row gap-4">
        {/* Section user */}
        <div className="w-full lg:w-2/6 shadow-md p-4 rounded-md border-t-2">
          <div className="text-center">
            <div className="w-full flex justify-center mb-2">
              <img className="w-24 h-24 rounded-full p-1 border-2 border-sky-400" src={member.picture} alt="Andra Coding" />
            </div>
            <h1 className="font-bold text-lg">{member.first_name} {member.last_name}</h1>
            <p>Freelencer</p>
          </div>
          <div className="flex flex-col gap-4">
            <div className="w-full p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <i className="text-sky-400 text-lg fa-solid fa-book" />
              </div>
              <div>
                <h2 className="font-bold mb-1">Username</h2>
                <p>{member.username}</p>
              </div>
            </div>
            <div className="w-full p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <i className="text-sky-400 text-lg fa-solid fa-envelope" />
              </div>
              <div>
                <h2 className="font-bold mb-1">Email</h2>
                <a className="text-sky-400 hover:underline" title="Send Email" href={`mailto: ${member.email}`} target="_blank">{member.email}</a>
              </div>
            </div>
            <div className="w-full p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <i className="text-sky-400 text-lg fa-brands fa-whatsapp" />
              </div>
              <div>
                <h2 className="font-bold mb-1">Whatsapp</h2>
                <a className="text-sky-400 hover:underline" title="Send Whatsapp" href={`https://wa.me/${member.whatsapp}`} target="_blank">{member.whatsapp}</a>
              </div>
            </div>
            <div className="w-full p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <i className="text-sky-400 text-lg fa-brands fa-instagram" />
              </div>
              <div>
                <h2 className="font-bold mb-1">Instagram</h2>
                <a className="text-sky-400 hover:underline" title="Check Instagram" href={`https://instagram.com/${member.email}`} target="_blank">{member.instagram}</a>
              </div>
            </div>
            <div className="w-full p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
              <div className="flex items-center gap-4 w-full">
                <div className="flex items-center justify-center w-8 h-8 rounded-md">
                  <i className="text-sky-400 text-lg fa-brands fa-discord" />
                </div>
                <div>
                  <h2 className="font-bold mb-1">Discord</h2>
                  <p>{member.discord}</p>
                </div>
              </div>
              {/* className={discord == '' ? 'hidden' : ''} */}
              <div >
                <button onClick={handleBtnFormDiscord} type="button" className="w-7 h-7 flex justify-center items-center bg-green-500 hover:border-500 hover:bg-green-600 ease-in-out duration-300 text-white text-sm rounded-md" title="Edit"><i className="fa-solid fa-edit" /></button>
              </div>
            </div>
            <form onSubmit={handleDiscordSubmit} className={`${!isActiveFormDiscord ? 'hidden' : ''} w-full flex gap-2 items-center mt-4`}>
              <input className="w-full px-4 py-2 border-2 border-slate-200 rounded-md outline-none focus:border-sky-300 hover:border-sky-300 ease-in duration-300" onChange={e=>setDiscord(e.target.value)} type="text" name="discord" id="discord" placeholder="Input Discord" title="Input Tag" required />
              <button type="submit" className="w-10 h-10 flex justify-center items-center bg-sky-500 hover:border-500 hover:bg-sky-600 ease-in-out duration-300 text-white  rounded-md" title="Edit Discord"><i className="fa-solid fa-save"></i></button>
            </form>
          </div>
        </div>
        {/* End Section user */}
        {/* Panel user */}
        <div className="w-full lg:w-4/6 shadow-md mt-8 p-2 lg:p-4">
          {/* Menu Button */}
          <div className="flex gap-2">
            <button onClick={handlePanelProfiles} data-target="profiles" type="button" className={`${panelProfiles == 1 ? 'bg-slate-200' : ''} btn-panel hover:bg-slate-200 shadow-sm py-2 px-4 rounded-md `}>Profile</button>
            <button onClick={handlePanelCourses} data-target="courses" type="button" className={`${panelCourses == 1 ? 'bg-slate-200' : ''} btn-panel hover:bg-slate-200 shadow-sm py-2 px-4 rounded-md `}>Courses</button>
          </div>
          {/* End Menu Button */}
          {/* Panel Section */}
          <div id="panelSection">
            {/* Panel ReviewProgress */}
            <div className={`${panelProfiles == 0 ? 'hidden' : ''} panel w-full shadow-md p-2 lg:p-4`} data-panel="profiles">
              <div className="flex flex-col lg:flex-row lg:flex-wrap gap-4">
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-solid fa-venus-mars" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Gender</h2>
                    <p> {member.gender == 'm' ? 'Male' : 'Female'}</p>
                  </div>
                </div>
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-solid fa-cake-candles" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Date of Birth</h2>
                    <p>{member.dob} <span className="text-sm italic">(29 Years Old)</span></p>
                  </div>
                </div>
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-solid fa-location-dot" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Adrress</h2>
                    <p className="capitalize">{member.city_name}, {member.province_name}</p>
                  </div>
                </div>
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-regular fa-note-sticky" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Bio</h2>
                    <p>{member.bio}</p>
                  </div>
                </div>
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-solid fa-code" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Skills</h2>
                    <p>{member.skill}</p>
                  </div>
                </div>
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-solid fa-laptop-code" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Level</h2>
                    <p>{member.level}</p>
                  </div>
                </div>
                <div className="w-full lg:w-[48%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
                  <div className="flex items-center justify-center w-8 h-8 rounded-md">
                    <i className="text-sky-400 text-lg fa-solid fa-terminal" />
                  </div>
                  <div>
                    <h2 className="font-bold mb-1">Reset Token</h2>
                    <p>{member.token}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* End Panel ReviewProgress */}
            {/* Panel Files */}
            <div className={`${panelCourses == 0 ? 'hidden' : ''} panel w-full shadow-md p-2 lg:p-4`} data-panel="courses">
              <div className="flex flex-col gap-4">
                { memberCourses.map(mCourse => (
                  <div key={mCourse.id} className="w-full flex gap-4 bg-slate-100 p-4 rounded-md  items-center relative">
                    <div>
                      <p className="text-xl font-bold h-16 w-16 bg-slate-400 text-white flex justify-center items-center rounded-md">
                      {Math.round(mCourse.progress)}%
                      </p>
                    </div>
                    <div className="w-full">
                      <h1 className="font-bold text-lg">{mCourse.course_name}</h1>
                      <div className="flex items-center gap-4 border p-2 rounded-md mt-4">
                        <div>
                          <div className="w-7 h-7 border-2 rounded-full flex justify-center items-center">
                          {mCourse.rating}
                          </div>
                        </div>
                        <div>
                          <h2 className="font-bold">Review :</h2>
                          <p>{mCourse.review}</p>
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-2 right-2">
                      <button onClick={()=>handleModalProgress(mCourse.id)} type="button" data-course-id={1} className="btn-review-progress w-7 h-7 flex justify-center items-center bg-white hover:border ease-in-out duration-300 text-sm rounded-md" title="Detail Progress"><i className="fa-solid fa-edit" /></button>
                    </div>
                  </div>
                ))}
                
              </div>
            </div>
            {/* End Panel Files */}
          </div>
          {/* End Panel Section */}
        </div>
        {/* End Panel user */}
        {/* Modal Review Progress */}
        <div id="modalReviewProgress" className={`${activeModal == 0 ? 'hidden' : ''} fixed top-0 left-0 w-full h-screen z-10 bg-black bg-opacity-25 flex justify-center items-center p-4`}>
          <div className="bg-white shadow-md p-4 rounded-md relative w-full lg:w-2/4 m-8 h-[500px]">
            <button onClick={()=>setActiveModal(0)} id="btnModalReviewProgressClose" type="button" className="absolute top-0 right-0 w-8 h-8 flex justify-center items-center bg-slate-100 text-red-500 rounded-md shadow-md"><i className="fa-solid fa-close" /></button>
            <div className='p-4'>
              <h2 className="font-semibold">Review Progress</h2>
              <hr className="bg-b-slate-200 mt-4" />
              {/* Table */}
              <div className="overflow-auto h-[400px]">
                <table className="w-full">
                  <thead className="bg-slate-600 text-white h-16 px-4">
                    <tr>
                      <th className="px-2 py-4 ">No</th>
                      <th className="px-6 py-4 ">Date Learn</th>
                      <th className="px-6 py-4 ">Playlist</th>
                      <th className="px-6 py-4 ">Lesson</th>
                      <th className="px-6 py-4 ">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberCourseProgress.map((cProgress) =>(
                      <tr key={cProgress.id} className="hover:bg-slate-100 border-b border-b-slate-200">
                        <td className="px-2 py-4 ">
                          {
                            number++
                          }
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">{cProgress.date_learn}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{cProgress.playlist_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{cProgress.lesson_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{cProgress.duration}</td>
                      </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* End Modal Review Progress */}
      </div>
    </section>

  )
}

export default MemberDetail