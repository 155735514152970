import React, { useEffect, useState } from 'react'
import {useParams } from 'react-router-dom'
import StatsCourse from './components/StatsCourse'
import ModalPlaylists from './components/ModalPlaylists'
import ModalFiles from './components/ModalFiles'
import TablePlaylists from './components/TablePlaylists'
import TableFiles from './components/TableFiles'
import TableLessons from './components/TableLessons'
import ButtonPanel from './components/ButtonPanel'
import { fetchData } from '../../config/Config';

const CourseDetail = () => {
  const xToken = localStorage.getItem('xToken');
  const { slug } = useParams();

  const [isActivePanel, setIsActivePanel] = useState({
    playlists : 1,
    files : 0,
    lessons : 0 
  })
  const [isActiveModal, setIsActiveModal] = useState({
    playlists : 0,
    files : 0,
    lessons : 0 
  })
  const [currentData,setCurrentData] = useState({});
  const [funcDataPlaylists, setFuncDataPlaylists] = useState(null);
  const [funcDataFiles, setFuncDataFiles] = useState(null);

  const handleBtnPanel=(e)=>{
    const target = e.target.getAttribute('data-target');
    if(target == 'playlists'){

      isActivePanel.playlists == 1 ? setIsActivePanel({playlists : 0}) : setIsActivePanel({playlists : 1})

    }else if(target == 'files'){

      isActivePanel.files == 1 ? setIsActivePanel({files : 0}) : setIsActivePanel({files : 1});

    }else if(target == 'lessons'){

      isActivePanel.lessons == 1 ? setIsActivePanel({lessons : 0}) : setIsActivePanel({lessons : 1})

    }
  }

  const handleBtnModal= async(e, values)=>{
    e.preventDefault();
    //const target = e.target.getAttribute('data-target');

    if(values.id){
      try {
        const data = await fetchData(`course/${slug}/${values.target}/${values.id}`,{
          method : 'GET',
          credentials: 'include',
          headers : {
            'Authorization': `Bearer ${xToken}`,
          }
        });
        setCurrentData(data.data);
      } catch (error) {
        alert(error);
      }
    }

    if(values.target == 'playlist'){
      isActiveModal.playlists == 1 ? setIsActiveModal({playlists : 0}) : setIsActiveModal({playlists : 1})
    }else if(values.target == 'file'){
      isActiveModal.files == 1 ? setIsActiveModal({files : 0}) : setIsActiveModal({files : 1})
    }else if(values.target == 'lesson'){
      isActiveModal.lessons == 1 ? setIsActiveModal({lessons : 0}) : setIsActiveModal({lessons : 1})
    }
  }

  const handleFetchPlaylists=(dataFetch)=>{
    setFuncDataPlaylists(()=>dataFetch);
  }
  const handleFetchFiles=(dataFetch)=>{
    setFuncDataFiles(()=>dataFetch);
  }
  

  return (
    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Course Details</h1>

      {/* Info Panel */}
      <StatsCourse xToken={xToken} slug={slug}/>
      {/* End Info Panel */}
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">

        {/* Menu Button */}
        <ButtonPanel handleBtnPanel={handleBtnPanel} isActivePanel={isActivePanel} />
        {/* End Menu Button */}

        <hr className="bg-b-slate-200 my-4" />
        <div className="flex justify-end">
          <h2 id="panelTitle" className="hidden font-bold bg-blue-50 inline py-2 px-4 rounded-md">Panel</h2>
        </div>

        {/* Modal Playlist */}
        <ModalPlaylists slug={slug} currentData={currentData} isActiveModal={isActiveModal} setIsActiveModal={setIsActiveModal} dataFetch={funcDataPlaylists}/>
        {/* End Modal Playlist */}

        {/* Modal File */}
        <ModalFiles slug={slug} currentData={currentData} isActiveModal={isActiveModal} setIsActiveModal={setIsActiveModal} dataFetch={funcDataFiles}/>
        {/* End Modal File */}

        {/* Panel Section */}
        <div id="panelSection">

          {/* Panel Playlist */}
          <TablePlaylists xToken={xToken} slug={slug}  isActivePanel={isActivePanel} handleBtnModal={handleBtnModal} dataFetch={handleFetchPlaylists}/>
          {/* End Panel Playlist */}

          {/* Panel Files */}
          <TableFiles xToken={xToken} slug={slug}  isActivePanel={isActivePanel} handleBtnModal={handleBtnModal} dataFetch={handleFetchFiles}  />
          {/* End Panel Files */}

          {/* Panel Lessons */}
          <TableLessons xToken={xToken} slug={slug} isActivePanel={isActivePanel}/>
          {/* End Panel Lessons */}

        </div>
        {/* End Panel Section */}
      </div>
      
    </section>


  )
}

export default CourseDetail