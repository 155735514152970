import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { fetchData, formatRupiah } from '../../config/Config';

const TransactionDetail = () => {
  const xToken = localStorage.getItem('xToken');
  const {id} = useParams();
  const [transaction, setTransaction] = useState({});

  useEffect(()=>{
    fetchTransaction();
  },[])

  const fetchTransaction= async()=>{
    try {
      const data = await fetchData(`transaction/${id}`,{
        method : 'GET',
        credentials : 'include',
        headers : {
          'Authorization': `Bearer ${xToken}`,
        }
      });
      setTransaction(data.data);
    } catch (error) {
      alert(error)
    }
  }
  return (
    <section className="p-4">
      <h1 className="font-bold text-xl mt-4">Transaction Details</h1>
      {/* Info Panel */}
      <div className="mt-8 w-full flex justify-between flex-wrap gap-4">
        <div className="w-[48%] lg:w-[23%] bg-green-500 py-4 px-6 lg:px-8 rounded-md flex items-center gap-4 text-white">
          <div>
            <h2 className="text-lg font-bold">Transaction Code</h2>
            <p className="font-bold">{transaction.transaction_code}</p>
          </div>
        </div>
        <div className="w-[48%] lg:w-[23%] shadow-md p-4 lg:px-6 rounded-md flex items-center gap-4 ">
          <div className="flex items-center justify-center w-16 h-16 bg-sky-400 text-white rounded-md">
            <i className="fa-solid fa-info text-3xl" />
          </div>
          <div>
            <h2 className="text-lg font-bold">Status</h2>
            <p>{transaction.transaction_status}</p>
          </div>
        </div>
        <div className="w-[48%] lg:w-[23%] shadow-md p-4 lg:px-6 rounded-md flex items-center gap-4 ">
          <div className="flex items-center justify-center w-16 h-16 bg-sky-400 text-white rounded-md">
            <i className="fa-solid fa-user text-3xl" />
          </div>
          <div>
            <h2 className="text-lg font-bold">Username</h2>
            <p><Link className="text-sky-400 hover:underline" title="Detail User" to={`/member/detail/${transaction.username}`}>{transaction.username}</Link></p>
          </div>
        </div>
        <div className="w-[48%] lg:w-[23%] shadow-md p-4 lg:px-6 rounded-md flex items-center gap-4 ">
          <div className="flex items-center justify-center w-16 h-16 bg-sky-400 text-white rounded-md">
            <i className="fa-solid fa-dollar text-3xl" />
          </div>
          <div>
            <h2 className="text-lg font-bold">Amount</h2>
            <p>{formatRupiah(transaction.gross_amount)}</p>
          </div>
        </div>
      </div>
      {/* End Info Panel */}
      <div className="w-full shadow-md mt-8 p-2 lg:p-4">
        <div className="flex flex-wrap gap-4">
          <div className="w-full lg:w-[49%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
            <div className="flex items-center justify-center w-8 h-8 rounded-md">
              <i className="text-sky-400 text-lg fa-solid fa-book" />
            </div>
            <div>
              <h2 className="font-bold mb-1">Course Name</h2>
              <a className="text-sky-400 hover:underline" title="Detail Course" href="#">{transaction.course_name}</a>
            </div>
          </div>
          <div className="w-full lg:w-[49%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
            <div className="flex items-center justify-center w-8 h-8 rounded-md">
              <i className="text-sky-400 text-lg fa-solid fa-star" />
            </div>
            <div>
              <h2 className="font-bold mb-1">Rating &amp; Review</h2>
              <div className="flex gap-2">
                <div className="mt-1">
                  <span className="bg-green-400 w-7 h-7 rounded-full flex justify-center items-center text-white">{transaction.rating}</span>
                </div>
                <p className="w-full">{transaction.review}</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[49%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
            <div className="flex items-center justify-center w-8 h-8 rounded-md">
              <i className="text-sky-400 text-lg fa-solid fa-clock" />
            </div>
            <div>
              <h2 className="font-bold mb-1">Order At</h2>
              <p>{transaction.transaction_time}</p>
            </div>
          </div>
          <div className="w-full lg:w-[49%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
            <div className="flex items-center justify-center w-8 h-8 rounded-md">
              <i className="text-sky-400 text-lg fa-solid fa-dollar" />
            </div>
            <div>
              <h2 className="font-bold mb-1">Paid At</h2>
              <p>{transaction.paid_at}</p>
            </div>
          </div>
          <div className="w-full lg:w-[49%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
            <div className="flex items-center justify-center w-8 h-8 rounded-md">
              <i className="text-sky-400 text-lg fa-regular fa-credit-card" />
            </div>
            <div>
              <h2 className="font-bold mb-1">Payment Type</h2>
              <p>{transaction.payment_type}</p>
            </div>
          </div>
          <div className="w-full lg:w-[49%] p-4 border-b border-b-slate-300 rounded-md flex items-center gap-4 ">
            <div className="flex items-center justify-center w-8 h-8 rounded-md">
              <i className="text-sky-400 text-lg fa-solid fa-file-invoice" />
            </div>
            <div>
              <h2 className="font-bold mb-1">Kupon Code</h2>
              <p>{transaction.kupon ? transaction.kupon : '-'}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default TransactionDetail