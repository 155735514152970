import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './config/AuthContext';
import Layout from './components/Layout';
import Home from "./pages/Home";
import Member from "./pages/members/Member";
import Course from "./pages/course/Course";
import Transaction from "./pages/transactions/Transaction";
import Review from "./pages/Review";
import Login from './pages/Login';
import CourseDetail from './pages/course/CourseDetail';
import MemberDetail from './pages/members/MemberDetail';
import TransactionDetail from './pages/transactions/TransactionDetail';
import CourseAdd from './pages/course/CourseAdd';
import CourseLessonAdd from './pages/course/CourseLessonAdd';

const PrivateRoute = ({ element }) => {
  const { auth } = React.useContext(AuthContext);
  return auth.xToken ? element : <Navigate to="/login" />;
};

const App=()=> {
  

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/" element={<PrivateRoute element={<Home />} />} />
            <Route path="/home" element={<PrivateRoute element={<Home />} />} />
            <Route path="/member" element={<PrivateRoute element={<Member />} />} />
            <Route path="/member/detail/:username" element={<PrivateRoute element={<MemberDetail />} />} />
            <Route path="/course" element={<PrivateRoute element={<Course />} />} />
            <Route path="/course/add" element={<PrivateRoute element={<CourseAdd />} />} />
            <Route path="/course/edit/:id" element={<PrivateRoute element={<CourseAdd />} />} />
            <Route path="/course/detail/:slug" element={<PrivateRoute element={<CourseDetail />} />} />
            <Route path="/course/lesson/:slug/add" element={<PrivateRoute element={<CourseLessonAdd />} />} />
            <Route path="/course/lesson/:slug/edit/:id" element={<PrivateRoute element={<CourseLessonAdd />} />} />
            <Route path="/transaction" element={<PrivateRoute element={<Transaction />} />} />
            <Route path="/transaction/detail/:id" element={<PrivateRoute element={<TransactionDetail />} />} />
            <Route path="/review" element={<PrivateRoute element={<Review />} />} />
            {/* <Route path="/" element={<Navigate to="/login" />} /> */}
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}


export default App;
